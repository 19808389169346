<template>
  <div class="text-center mb-2">
    <v-card-title class="justify-center font-weight-bold"
      >Lueder, Larkin & Hunter, LLC</v-card-title
    >
    <v-card-subtitle>{{ type }}</v-card-subtitle>
    <v-chip>{{ dateFormat() }}</v-chip>
  </div>
</template>

<script>
import { dateFormat } from "@/functions";
export default {
  name: "report_header",
  methods: {
    dateFormat
  },
  props: {
    type: {
      type: String
    }
  }
};
</script>

<style scoped></style>
